import React, { Fragment } from 'react';
import { ProgressBar } from 'react-bootstrap';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { FaChrome, FaAndroid, FaApple, FaCcVisa, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';
import Lottie from 'react-lottie';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Helmet from '../../components/common/Helmet';
import Developer from '../../assets/images/lotties/developer.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Developer,
};

const SlideList = [
  {
    textPosition: 'text-left',
    category: 'Somos una agencia de',
    title: (
      <span>
        Diseño &<br /> desarrollo de software.
      </span>
    ),
    bgImage: 'bg-image-1',
  },
];

const ServiceList = [
  {
    icon: <FaChrome />,
    title: 'Desarrollo Aplicaciones Web',
    description: 'Lleva tu negocio a otro nivel y optimizalo en real time.',
  },
  {
    icon: <FaAndroid />,
    title: 'Desarrollo Aplicaciones Android',
    description:
      'Diseñamos y desarrollamos soluciones personalizadas para publicarlas en tu Play Store.',
  },
  {
    icon: <FaApple />,
    title: 'Desarrollo Aplicaciones iOS',
    description:
      'Diseñamos y desarrollamos soluciones personalizadas para publicarlas en tu App Store.',
  },
  {
    icon: <FaCcVisa />,
    title: 'Pagos en línea',
    description: 'Ofrecemos pagos en línea para todas las aplicaciones que lo necesiten.',
  },
  {
    icon: <FaWhatsapp />,
    title: 'Integraciones',
    description:
      'Integramos aplicaciones con diferentes servicios externos como Whatsapp, Gmail, o cualquier servicio que necesites.',
  },
  {
    icon: <FaPhoneAlt />,
    title: 'Soporte',
    description:
      'Contamos con servicio de soporte 24/7 para proteger y mantener tus activos digitales',
  },
];

const Home = () => (
  <>
    <Helmet pageTitle="Ideascol" />
    <Header />

    {/* Start Slider Area   */}
    <div className="slider-wrapper" id="home">
      <div className="slider-activation">
        {SlideList.map((value, index) => (
          <div
            className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
            key={index}
            data-black-overlay="8"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ''}
                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                    {value.description ? <p className="description">{value.description}</p> : ''}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    {/* End Slider Area   */}

    {/* Start About Area  */}
    <div className="rn-about-area ptb--120 bg_color--1" id="about">
      <div className="rn-about-wrapper">
        <div className="container">
          <div className="row row--35 align-items-center">
            <div className="col-lg-7">
              <div className="about-inner inner">
                <div className="section-title">
                  <h2 className="title">¿Qué hacemos?</h2>
                  <p className="description">
                    Construimos software a la medida y de acuerdo a tus necesidades. Trabajamos con
                    las últimas tecnologías para brindarte lo mejor contando con más de 20 años de
                    experiencia en diferentes tecnologías y metodologías 🚀.
                  </p>
                </div>
                <div className="row mt--30">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="about-us-list">
                      <h3 className="title">Trabajo continúo. 🤓</h3>
                      <div className="rn-progress-bar progress-bar--3">
                        <div className="single-progress custom-color--1">
                          <h6 className="title">Diseño</h6>
                          <ProgressBar now={86} />
                          <span className="label">86%</span>
                        </div>

                        <div className="single-progress custom-color--2">
                          <h6 className="title">Análisis</h6>
                          <ProgressBar now={90} />
                          <span className="label">90%</span>
                        </div>

                        <div className="single-progress custom-color--3">
                          <h6 className="title">Desarrollo</h6>
                          <ProgressBar now={89} />
                          <span className="label">89%</span>
                        </div>

                        <div className="single-progress custom-color--4">
                          <h6 className="title">Pruebas y Adaptación</h6>
                          <ProgressBar now={80} />
                          <span className="label">80%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 mt_md--40 mt_sm--40">
              <div className="thumbnail">
                <Lottie className="w-100" options={defaultOptions} height={320} width={320} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End About Area  */}

    {/* Start Service Area */}
    <div className="service-area creative-service-wrapper pb--120 bg_color--1" id="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb--30">
              <h2>Nuestros servicios</h2>
              <p>
                😱 ¡Que no te sorprenda! <br />
                Nuestros clientes lo saben y nuestros resultados lo demuestran. 😎
              </p>
            </div>
          </div>
        </div>
        <div className="row creative-service">
          {ServiceList.map((val, i) => (
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--2 text-center">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="btn center">
            <a
              className="rn-button-style--2 btn-solid"
              href="https://ideascol.typeform.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              Adquiere nuestros servicios!
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* End Service Area */}

    {/* Start Video Area  */}
    {/* <div className="rn-section pb--120 bg_color--1">
            <div className="container">
                    <div className="row sercice-details-content align-items-center">
                    <div className="col-lg-12">
                        <div className="thumb position-relative">
                            <img className="w-100" src="/assets/images/bg/bg-image-26.jpg" alt="Service Images"/>
                            <VideoModal />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    {/* End Video Area  */}

    {/* Start Brand Area  */}
    {/* <div className="rn-brand-area pb--120 bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <BrandTwo />
          </div>
        </div>
      </div>
    </div> */}
    {/* End Brand Area  */}

    {/* Start Back To Top */}
    <div className="backto-top">
      <ScrollToTop showUnder={160}>
        <FiChevronUp />
      </ScrollToTop>
    </div>
    {/* End Back To Top */}

    <Footer />
  </>
);

export default Home;
